import Papa from "papaparse";
//@ts-ignore
import questionsCSV from "./questions_2024.csv";

export enum QuestionType {
  Task = 1,
  Rule = 3,
  //Rule2 = 2,
  Enumeration = 4,
  Choice = 14,
  ChoosePlayer = 24,
  Misc = 23,
  //Task2 = 25,
  OnEx = 5,
  Post = 15,
}

export type Question = {
  key?: string;
  parent_key?: string;
  text: string;
  type: QuestionType;
  nb_players: number;
  is_new: boolean;
  language: string;
};

export type QuestionInstance = Question & {
  players: string[];
  sips: number[];
};

export const loadCSV = () =>
  new Promise<Papa.ParseResult<Question>>((res, rej) => {
    Papa.parse<Question>(questionsCSV, {
      download: true,
      header: true,
      transform: (val) => {
        if (!!val && typeof val === "string" && !!val) {
          const parsedValue = parseInt(val);
          if (parsedValue || parsedValue === 0) {
            return parsedValue;
          }
          return val
            .replaceAll("Picolo", "Jonnicolo")
            .replaceAll("eine Strafe", "$ Schlücke")
            .replaceAll("a penalty", "$ sips")
            .replaceAll("einen Schluck", "$ Schlücke")
            .replaceAll("take a maximum penalty", "empty their drink")
            .replaceAll("Take a maximum penalty", "Empty their drink")
            .replaceAll("Give out a maximum penalty", "Give out a full drink")
            .replaceAll("penalties", "sips")
            .replaceAll("penalty", "sip")
            .replaceAll("Strafen", "Schlücke")
            .replaceAll("Strafe", "Schluck");
        }
        return !!val ? parseInt(val) || val : undefined;
      },
      complete: (data) => {
        for (const question of data.data) {
          // @ts-ignore
          if (question.type === 2) {
            question.type = 3;
          }
          // @ts-ignore
          else if (question.type === 25) {
            question.type = 1;
          }
        }
        res(data);
      },
      error: rej,
    });
  });
