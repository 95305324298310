import {
  MAX_DISTANCE_BETWEEN_FOLLOWUP,
  MIN_DISTANCE_BETWEEN_FOLLOWUP,
} from "./constants";
import {
  randomNumberBetween,
  randomElement,
  roundToBeEven,
  selectN,
  shuffleList,
} from "./helpers";
import { loadCSV, Question, QuestionInstance, QuestionType } from "./Questions";
import { store } from "./redux/store";

const findResolution = (question: Question, questionList: Question[]) => {
  const resolutions = questionList.filter(
    ({ parent_key }) => parent_key === question.key
  );
  return randomElement(resolutions);
};

const STANDALONE_QUESTIONS: QuestionType[] = [
  QuestionType.Task,
  QuestionType.Enumeration,
  QuestionType.Choice,
  QuestionType.OnEx,
];
const FOLLOW_UP_QUESTIONS: QuestionType[] = [
  // There are some weird "Task"-Questions that are actually follow-ups
  QuestionType.Task,
  QuestionType.Rule,
  QuestionType.Misc,
];

const generateQuestionInstance = (question: Question): QuestionInstance => {
  const { minSips, maxSips, players } = store.getState().settings;

  return {
    ...question,
    players: selectN(players, (question.text.match(/%s/g) || []).length),
    sips:
      question.text
        .match(/\$/g)
        ?.map((_) => randomNumberBetween(minSips, maxSips)) ?? [],
  };
};

export const generateQuestionList = async () => {
  const { questionCount, players, questionDistribution, language } =
    store.getState().settings;

  const playerCount = players.length;
  const questions = (await loadCSV()).data.filter(
    ({ nb_players, language: q_lang }) =>
      nb_players <= playerCount && q_lang === language
  );

  const questionsByType = questions.reduce((acc, question) => {
    const { type } = question;
    acc[type] ??= [];
    acc[type].push(question);
    return acc;
  }, {} as Record<QuestionType, Question[]>);

  const selectedStandaloneQuestions = STANDALONE_QUESTIONS.map((type) =>
    selectN(
      questionsByType[type].filter((i) => !i.key && !i.parent_key),
      questionCount * questionDistribution[type]
    )
  ).flat();

  const selectedStandaloneQuestionsShuffled = shuffleList(
    selectedStandaloneQuestions
  );

  const selectedFollowUpQuestions = FOLLOW_UP_QUESTIONS.map((type) =>
    selectN(
      questionsByType[type].filter((i) => !i.parent_key && !!i.key),
      roundToBeEven(questionCount * questionDistribution[type]) / 2
    )
  ).flat();

  const selectedFollowUpQuestionsShuffled = shuffleList(
    selectedFollowUpQuestions
  );

  const selectedQuestions = [...selectedStandaloneQuestionsShuffled];

  for (const followUpQuestion of selectedFollowUpQuestionsShuffled) {
    const resolution = findResolution(followUpQuestion, questions);
    const randIndex = randomNumberBetween(
      selectedQuestions.length - MAX_DISTANCE_BETWEEN_FOLLOWUP
    );

    selectedQuestions.splice(randIndex, 0, followUpQuestion);

    let resolutionIndex =
      randIndex +
      randomNumberBetween(
        MAX_DISTANCE_BETWEEN_FOLLOWUP,
        MIN_DISTANCE_BETWEEN_FOLLOWUP
      );

    // 'Misc'- & 'Task'-Questions should be resolved immediately
    if (
      followUpQuestion.type === QuestionType.Misc ||
      followUpQuestion.type === QuestionType.Task
    ) {
      resolutionIndex = randIndex + 1;
    }

    selectedQuestions.splice(resolutionIndex, 0, resolution);
  }

  return selectedQuestions.map((q) => generateQuestionInstance(q));
};
