import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonInput,
  IonItem,
  IonLabel,
  IonListHeader,
  IonRange,
  IonSelect,
  IonSelectOption,
} from "@ionic/react";

import { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PercentageInput } from "../../components/PercentageInput";
import { QUESTION_TYPE_TITLE } from "../../constants";
import { QuestionType } from "../../Questions";
import {
  setSipRange,
  settingsSet,
  distributionSet,
  resetSettings,
  QUESTION_TYPES_LIST,
} from "../../redux/settings";
import { RootState } from "../../redux/store";

type Props = {};

export const SettingsCard: FC<Props> = (props) => {
  const dispatch = useDispatch();
  const settings = useSelector((state: RootState) => state.settings);

  const [rangeValue, setRangeValue] = useState({
    lower: settings.minSips,
    upper: settings.maxSips,
  });

  useEffect(() => {
    const timer = setTimeout(() => {
      setRangeValue({
        lower: settings.minSips,
        upper: settings.maxSips,
      });
    }, 20);
    return () => clearTimeout(timer);
  }, []);

  useEffect(
    () =>
      setRangeValue({
        lower: settings.minSips,
        upper: settings.maxSips,
      }),
    [settings.minSips, settings.maxSips]
  );
  return (
    <IonCard>
      <IonCardHeader>
        <IonCardTitle>Einstellungen</IonCardTitle>
      </IonCardHeader>
      <IonCardContent>
        <IonListHeader>
          <IonLabel>Fragensprache</IonLabel>
        </IonListHeader>
        <IonItem lines="none">
          <IonLabel>Sprache</IonLabel>
          <IonSelect
            value={settings.language}
            placeholder="Select Language"
            onIonChange={(e) =>
              dispatch(
                settingsSet({
                  key: "language",
                  value: e.detail.value,
                })
              )
            }
          >
            <IonSelectOption value="de">Deutsch</IonSelectOption>
            <IonSelectOption value="en">English</IonSelectOption>
          </IonSelect>
        </IonItem>

        <IonListHeader>
          <IonLabel>Generelles</IonLabel>
        </IonListHeader>
        <IonItem lines="none">
          <IonInput
            label="Fragen pro Runde"
            type="number"
            value={settings.questionCount}
            onInput={(e) =>
              dispatch(
                settingsSet({
                  key: "questionCount",
                  value: (e.target as any).value,
                })
              )
            }
          />
        </IonItem>
        <IonItem lines="none">
          <IonLabel style={{ marginBottom: 0 }}>Anzahl Schlücke</IonLabel>
        </IonItem>
        <IonItem lines="none" style={{ marginTop: 0 }}>
          <IonRange
            dualKnobs={true}
            min={1}
            max={20}
            step={1}
            snaps={true}
            value={rangeValue}
            onIonInput={(e) => dispatch(setSipRange(e.detail.value as any))}
          >
            <div slot="start" style={{ width: "2ch" }}>
              {settings.minSips}
            </div>
            <div slot="end" style={{ width: "2ch" }}>
              {settings.maxSips}
            </div>
          </IonRange>
        </IonItem>

        <IonListHeader>
          <IonLabel>Fragen-Verteilung</IonLabel>
        </IonListHeader>
        {QUESTION_TYPES_LIST.map((type) => (
          <PercentageInput
            key={type}
            value={settings.questionDistribution[type]}
            label={QUESTION_TYPE_TITLE[type as QuestionType] ?? "Normale Frage"}
            onChange={(value) =>
              dispatch(
                distributionSet({
                  type: type as QuestionType,
                  value: value,
                })
              )
            }
          />
        ))}
        <IonButton
          expand="block"
          fill="clear"
          color="warning"
          onClick={() => dispatch(resetSettings())}
        >
          Zurücksetzen
        </IonButton>
      </IonCardContent>
    </IonCard>
  );
};
