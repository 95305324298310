import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Question, QuestionInstance, QuestionType } from "../Questions";
import { RootState } from "./store";

export type QuestionDistribution = {
  [key in QuestionType]: number;
};
export type SettingsState = {
  questionCount: number;
  minSips: number;
  maxSips: number;
  players: string[];
  questions: QuestionInstance[];
  questionIndex: number;
  questionDistribution: QuestionDistribution;
  language: string;
};

const initialState = {
  questionCount: 45,
  minSips: 1,
  maxSips: 5,
  players: [],
  questions: [],
  questionIndex: 0,
  questionDistribution: {
    [QuestionType.Task]: 48.99 / 100,
    [QuestionType.Rule]: 0.16 / 100,
    [QuestionType.Enumeration]: 6.67 / 100,
    [QuestionType.Choice]: 13.33 / 100,
    [QuestionType.Misc]: 11.11 / 100,
    [QuestionType.OnEx]: 0.03 / 100,
    [QuestionType.Post]: 0,
    [QuestionType.ChoosePlayer]: 0.06 / 100,
  },
  language: "de",
} as SettingsState;

export const QUESTION_TYPES_LIST = Object.keys(
  initialState.questionDistribution
) as unknown as QuestionType[];

export const settingsSlice = createSlice({
  name: "Settings",
  initialState,
  reducers: {
    settingsSet: <T extends keyof SettingsState>(
      state: SettingsState,
      { payload }: PayloadAction<{ key: T; value: SettingsState[T] }>
    ) => {
      state[payload.key] = payload.value;
    },
    distributionSet: (
      state: SettingsState,
      { payload }: PayloadAction<{ type: QuestionType; value: number }>
    ) => {
      state.questionDistribution[payload.type] = payload.value;
    },
    addPlayer: (state: SettingsState, { payload }: PayloadAction<string>) => {
      state.players.push(payload);
    },
    removePlayer: (
      state: SettingsState,
      { payload }: PayloadAction<string>
    ) => {
      state.players = state.players.filter((p) => p !== payload);
    },
    setSipRange: (
      state: SettingsState,
      { payload }: PayloadAction<{ lower: number; upper: number }>
    ) => {
      state.minSips = payload.lower;
      state.maxSips = payload.upper;
    },
    previousQuestion: (state) => {
      state.questionIndex--;
    },
    nextQuestion: (state) => {
      state.questionIndex++;
    },
    endGame(state) {
      state.questionIndex = state.questions.length;
    },
    resetSettings() {
      return { ...initialState };
    },
  },
});
export const {
  addPlayer,
  removePlayer,
  settingsSet,
  distributionSet,
  setSipRange,
  nextQuestion,
  previousQuestion,
  endGame,
  resetSettings,
} = settingsSlice.actions;

export const SettingsReducer = settingsSlice.reducer;
export const createSettingsSelector = (key: keyof SettingsState) =>
  createSelector(
    (state: RootState) => state.settings,
    (settings) => settings[key]
  );
